import { Auth, Storage } from 'aws-amplify';
import AWS from 'aws-sdk';
import * as CryptoJS from '@/lib/util/crypto';
import * as Cookie from '@/lib/util/cookies';
import i18n from '@/plugins/i18n';

const getterObject = {
  get year() {
    return new Date().getFullYear();
  },
  get month() {
    return new Date().getMonth();
  },
  get dob() {
    return new Date(getterObject.year - 24, getterObject.month, 1);
  },
};
export const state = () => ({
  isAuthenticated: false,
  cognitoUser: {},
  currentUser: {},
  userConfirm: {},
  emailUserResetPass: '',
  isAuthenticatedAdmin: false,
  currentAdmin: {},
});

export const mutations = {
  SET_CURRENT_USER(state, user) {
    if (user === null) {
      state.currentUser = {};
    } else {
      state.currentUser = user;
    }
  },
  SET_COGNITO_USER(state, cognitoUser) {
    state.isAuthenticated = !!cognitoUser;
    state.cognitoUser = cognitoUser;
  },
  SET_USER_CONFIRM(state, email) {
    state.userConfirm = { email };
  },
  SET_EMAIL_USER_RESET_PASS(state, email) {
    state.emailUserResetPass = email;
  },
  SET_CURRENT_ADMIN(state, currentAdmin) {
    state.isAuthenticatedAdmin = !!currentAdmin;
    state.currentAdmin = currentAdmin;
  },
  ADD_PROFILE_TO_USER(state, profile) {
    const { currentUser } = state;
    currentUser.profiles.items.push(profile);
    state.currentUser = { ...currentUser };
  },
};

export const actions = {
  async load({ commit, dispatch }) {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];
      if (groups && groups.includes('Admin')) {
        commit('SET_CURRENT_ADMIN', cognitoUser);
      } else {
        commit('SET_COGNITO_USER', cognitoUser);
        if (cognitoUser) {
          await dispatch('auth/getCurrentUser', cognitoUser.username, { root: true });
        }
      }
      return cognitoUser;
    } catch (error) {
      commit('SET_COGNITO_USER', null);
      commit('SET_CURRENT_ADMIN', null);
    }
  },
  async registerEmail({ commit, dispatch }, email) {
    try {
      const cognitoUser = await Auth.signUp({
        username: email,
        password: process.env.VUE_APP_DEFAULT_USER_PASSWORD,
      });
      commit('SET_USER_CONFIRM', cognitoUser.user.username);
      return true;
    } catch (error) {
      if (error.code === 'UsernameExistsException') {
        const res = await dispatch('resendSignUp', email);
        return res;
      }
      this.$toast.error(i18n.t('common.errorWithMessage', { message: error.message }));
      return false;
    }
  },
  async resendSignUp({ commit }, email) {
    try {
      await Auth.resendSignUp(email);
      commit('SET_USER_CONFIRM', email);
      this.$toast.global.info(i18n.t('auth.alreadyRegistered', { email }));
      return true;
    } catch (error) {
      if (error.message === 'User is already confirmed.') {
        this.$toast.error(i18n.t('auth.userAlreadyConfirmed'));
      } else {
        this.$toast.error(i18n.t('common.errorWithMessage', { message: error.message }));
      }
      return false;
    }
  },
  async confirmSignUp({ dispatch }, { email, code }) {
    const confirm = await Auth.confirmSignUp(email, code);
    await dispatch('logout');
    return confirm;
  },
  async signIn({ commit, dispatch }, { email, password, isOauth }) {
    const cognitoUser = await Auth.signIn(email, password);
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      if (isOauth) {
        await Auth.completeNewPassword(cognitoUser, password);
      } else {
        cognitoUser.isForceResetPassword = true;
        commit('SET_COGNITO_USER', cognitoUser);
        return cognitoUser;
      }
    }
    const groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups'];
    if (groups && groups.includes('Admin')) {
      commit('SET_CURRENT_ADMIN', cognitoUser);
      Cookie.default.setCookie('adminEmail', CryptoJS.default.encryptMessage(email), 1);
      Cookie.default.setCookie('adminPassword', CryptoJS.default.encryptMessage(password), 1);
    } else {
      commit('SET_COGNITO_USER', cognitoUser);
      if (cognitoUser) {
        await dispatch('auth/getCurrentUser', cognitoUser.username, { root: true });
      }
    }
    return cognitoUser;
  },
  async getCurrentUser({ commit, dispatch }, id) {
    const currentUser = await dispatch('api/get', { query: 'getUser', id }, { root: true });
    if (currentUser) {
      Cookie.default.setCookie('currentUserName', currentUser.name, 1);
      Cookie.default.setCookie('currentUserEmail', currentUser.email, 1);
      if (currentUser.isDisabled || currentUser.isDeleted) {
        await dispatch('auth/logout', '', { root: true });
        return;
      }
      if (currentUser.avatar) {
        currentUser.avatarUrl = await Storage.get(currentUser.avatar.key.normalize('NFD'));
      }
      commit('SET_CURRENT_USER', currentUser);
    }
    return currentUser;
  },
  async logout({ commit, dispatch }) {
    await Auth.signOut();
    if (AWS.config.credentials && AWS.config.credentials.clearCachedId) {
      AWS.config.credentials.clearCachedId();
    }
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('CognitoIdentityId')) localStorage.removeItem(key);
    });
    Cookie.default.setCookie('currentUserName', '', -1);
    Cookie.default.setCookie('currentUserEmail', '', -1);
    dispatch('profile/removeSpaceProfile', {}, { root: true });
    dispatch('localStoreManager/removeUserStorage', {}, { root: true });
    commit('SET_CURRENT_USER', null);
    commit('SET_COGNITO_USER', null);
    commit('SET_CURRENT_ADMIN', null);
  },
  async createProfile({ commit, dispatch, state }, { profile, newPassword, isOAuth = false }) {
    try {
      const { cognitoUser } = state;
      if (!isOAuth) {
        const oldPassword = process.env.VUE_APP_DEFAULT_USER_PASSWORD;
        await Auth.changePassword(cognitoUser, oldPassword, newPassword);
      }
      const profilePayload = {
        ...profile,
        receiveEmail: true,
        receiveEmailCycle: 3,
        receiveNotify: true,
        email: cognitoUser.attributes.email,
      };
      const userData = await dispatch('user/createUser', profilePayload, { root: true });
      if (userData) {
        Cookie.default.setCookie('currentUserName', profile.name, 1);
        Cookie.default.setCookie('currentUserEmail', cognitoUser.attributes.email, 1);
        commit('SET_CURRENT_USER', userData);
      }
      const userAccountPayload = {
        userID: userData.id,
        email: cognitoUser.attributes.email,
        password: CryptoJS.default.encryptMessage(newPassword),
      };
      if (!isOAuth) {
        await dispatch('userAccount/createUserAccount', userAccountPayload, { root: true });
      }
    } catch (error) {
      console.log(error);
    }
  },
  async adminCreateProfile({ dispatch }, { profile, newPassword }) {
    try {
      const profilePayload = {
        ...profile,
        email: profile.email,
      };
      const userData = await dispatch('user/createUser', profilePayload, { root: true });
      const userAccountPayload = {
        userID: userData.id,
        email: profile.email,
        password: CryptoJS.default.encryptMessage(newPassword),
      };
      await dispatch('userAccount/createUserAccount', userAccountPayload, { root: true });
    } catch (error) {
      console.log(error);
    }
  },
  async forgotPassword({ commit }, email) {
    commit('SET_EMAIL_USER_RESET_PASS', email);
    await Auth.forgotPassword(email);
  },
  async forgotPasswordSubmit({ commit }, { email, code, newPassword }) {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      commit('SET_EMAIL_USER_RESET_PASS', null);
      return true;
    } catch (e) {
      return e;
    }
  },
};

export const getters = {
  userConfirm: (state) => state.userConfirm,
  emailUserResetPass: (state) => state.emailUserResetPass,
  currentUser: (state) => state.currentUser,
  cognitoUser: (state) => state.cognitoUser,
  currentAdmin: (state) => state.currentAdmin,
  isAuthenticated: (state) => state.isAuthenticated,
};
