/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      birthday
      gender
      address
      phoneNumber
      career
      avatar {
        bucket
        region
        key
      }
      description
      email
      receiveNotify
      receiveEmail
      receiveEmailCycle
      isDisabled
      isDeleted
      isUnregistered
      isReadyDelete
      fcmToken
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          isCoachGroup
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedGroups {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      memo
      createdAt
      updatedAt
      spaces(limit: 100000) {
        items {
          id
          name
          description
          avatar {
            bucket
            region
            key
          }
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles(limit: 100000) {
        items {
          id
          name
          career
          avatar {
            bucket
            region
            key
          }
          description
          userID
          spaceID
          isSpaceCoach
          notifySetting {
            items {
              id
              profileID
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
              visionBoardSetting
              createdAt
              updatedAt
            }
            nextToken
          }
          confirmed
          status
          role
          reason
          groupNotifySetting {
            items {
              id
              profileID
              groupID
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
              createdAt
              updatedAt
            }
            nextToken
          }
          isEditOnce
          createdAt
          updatedAt
          filterVisionMessage {
            myAction
            someOneElseAction
          }
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        fcmToken
        groups {
          nextToken
        }
        joinedGroups {
          items {
            id
            groupID
            userID
            spaceID
            createdAt
            updatedAt
          }
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSpacePublic = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      inviteCode
      userID
      editors
      members
      type
      status
      planID
      planName
      maxMember
      currentUpload
      currentStorage
      monthlyUpload
      maxStorage
      paymentFee
      paymentCycle
      paymentMethod
      dayLimitation
      isActiveCoachRoom
      createdAt
      updatedAt
    }
  }
`;
export const getSpace = /* GraphQL */ `
  query GetSpace($id: ID!) {
    getSpace(id: $id) {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      inviteCode
      userID
      editors
      members
      isActiveCoachRoom
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        fcmToken
        groups {
          nextToken
        }
        joinedGroups {
          items {
            id
          }
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      groups {
        items {
          id
          name
          description
          userID
          spaceID
          status
          isCoachGroup
          createdAt
          updatedAt
          public {
            items {
              id
            }
            nextToken
          }
          joinedMembers {
            items {
              id
              groupID
              userID
            }
            nextToken
          }
        }
        nextToken
      }
      public {
        items {
          id
          name
          description
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      status
      planID
      planName
      maxMember
      currentUpload
      currentStorage
      monthlyUpload
      maxStorage
      paymentFee
      paymentCycle
      paymentMethod
      dayLimitation
      memo
      createdAt
      updatedAt
      videos {
        items {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          createdAt
          updatedAt
        }
        nextToken
      }
      profiles {
        items {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
          avatar {
            bucket
            region
            key
          }
          isSpaceCoach
          user {
            joinedGroups {
              items {
                id
              }
              nextToken
            }
          }
        }
        nextToken
      }
      joinSpaceKey {
        items {
          id
          spaceID
          userName
          spaceName
          content
          avatarKey
          requestRole
          keyAccess
          createdAt
          updatedAt
          emailInvite
        }
        nextToken
      }
      spaceInvitation {
        items {
          id
          spaceID
          userID
          email
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const listSpaces = /* GraphQL */ `
  query ListSpaces(
    $filter: ModelSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          items {
            id
          }
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        dayLimitation
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
        monthlyYoutubeUpload
        totalYoutubeUpload
        isActiveCoachRoom
      }
      nextToken
    }
  }
`;
export const getPublicSpace = /* GraphQL */ `
  query GetPublicSpace($id: ID!) {
    getPublicSpace(id: $id) {
      id
      name
      description
      avatar {
        bucket
        region
        key
      }
      spaceID
      owner
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const listPublicSpaces = /* GraphQL */ `
  query ListPublicSpaces(
    $filter: ModelPublicSpaceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicSpaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        spaceID
        owner
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      userID
      spaceID
      isCoachGroup
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        fcmToken
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      public {
        items {
          id
          name
          description
          groupID
          spaceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      joinedMembers {
        items {
          id
          groupID
          userID
          spaceID
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups($filter: ModelGroupFilterInput, $limit: Int, $nextToken: String) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        userID
        spaceID
        isCoachGroup
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          avatar {
            bucket
            region
            key
          }
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        public {
          items {
            id
          }
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPublicGroup = /* GraphQL */ `
  query GetPublicGroup($id: ID!) {
    getPublicGroup(id: $id) {
      id
      name
      description
      groupID
      spaceID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPublicGroups = /* GraphQL */ `
  query ListPublicGroups(
    $filter: ModelPublicGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        groupID
        spaceID
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJoinGroup = /* GraphQL */ `
  query GetJoinGroup($id: ID!) {
    getJoinGroup(id: $id) {
      id
      groupID
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        fcmToken
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJoinGroups = /* GraphQL */ `
  query ListJoinGroups($filter: ModelJoinGroupFilterInput, $limit: Int, $nextToken: String) {
    listJoinGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          avatar {
            bucket
            region
            key
          }
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
          isCoachGroup
          public {
            items {
              id
            }
          }
          user {
            receiveNotify
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupVideo = /* GraphQL */ `
  query GetGroupVideo($id: ID!) {
    getGroupVideo(id: $id) {
      id
      groupID
      videoID
      group {
        id
        name
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        public {
          nextToken
        }
        joinedMembers {
          nextToken
        }
        status
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          items {
            id
          }
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          items {
            profile {
              userID
            }
          }
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listGroupVideos = /* GraphQL */ `
  query ListGroupVideos($filter: ModelGroupVideoFilterInput, $limit: Int, $nextToken: String) {
    listGroupVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        videoID
        group {
          id
          name
          description
          userID
          spaceID
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        video {
          id
          title
          description
          status
          thumbnail {
            bucket
            region
            key
          }
          content {
            bucket
            region
            key
          }
          profileID
          spaceID
          inviteCode
          publicToSpace
          type
          videoUrl
          tags
          space {
            id
            name
            description
            inviteCode
            userID
            editors
            members
            type
            status
            planID
            planName
            maxMember
            monthlyUpload
            maxStorage
            paymentFee
            paymentCycle
            paymentMethod
            createdAt
            updatedAt
          }
          duration
          infoID
          createdAt
          updatedAt
          likes {
            items {
              id
              profile {
                userID
              }
            }
            nextToken
          }
          info {
            id
            watched
            createdAt
            updatedAt
          }
          comments(limit: 10000) {
            items {
              profile {
                userID
              }
              issues {
                items {
                  id
                  targetID
                  targetType
                  issueID
                  profileID
                  createdAt
                  updatedAt
                  issue {
                    id
                    goalID
                    goal {
                      id
                      visionID
                      title
                      status
                      color
                      vision {
                        id
                        status
                      }
                    }
                    status
                    title
                  }
                }
                nextToken
              }
            }
            nextToken
          }
          guestComments(limit: 10000) {
            items {
              id
            }
            nextToken
          }
          profile {
            id
            name
            career
            description
            userID
            spaceID
            confirmed
            status
            role
            reason
            createdAt
            updatedAt
          }
          issues {
            items {
              id
              targetID
              targetType
              issueID
              profileID
              createdAt
              updatedAt
              issue {
                id
                goalID
                goal {
                  id
                  visionID
                  title
                  status
                  color
                  vision {
                    id
                    status
                  }
                }
                status
                title
              }
            }
            nextToken
          }
          goals {
            items {
              id
              targetID
              targetType
              goalID
              profileID
              createdAt
              updatedAt
              goal {
                id
                visionID
                status
                title
                color
                vision {
                  id
                  status
                  coachRoomID
                }
              }
            }
            nextToken
          }
          visions {
            items {
              id
              targetID
              targetType
              visionID
              profileID
              createdAt
              updatedAt
              vision {
                id
                status
                title
                coachRoomID
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      content
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profileID
      spaceID
      files {
        bucket
        region
        key
      }
      parentID
      parent {
        id
        content
        profileID
        spaceID
        files {
          bucket
          region
          key
        }
        mentions
        createdAt
        updatedAt
        likes {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
        type
      }
      type
      mentions
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats($filter: ModelChatFilterInput, $limit: Int, $nextToken: String) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        attachedVideos(limit: 100000) {
          items {
            id
            attachToID
            videoID
            createdAt
            updatedAt
            video {
              title
              status
              thumbnail {
                bucket
                region
                key
              }
              publicToSpace
              publicToGroup(limit: 100000) {
                items {
                  id
                  groupID
                  videoID
                  group {
                    name
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        profileID
        spaceID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          createdAt
          updatedAt
          type
        }
        type
        mentions
        createdAt
        updatedAt
        likes {
          items {
            id
            targetID
            targetType
            profileID
            emoji
            profile {
              id
              name
              avatar {
                bucket
                region
                key
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          avatar {
            bucket
            region
            key
          }
          notifySetting {
            items {
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
              visionBoardSetting
            }
          }
          groupNotifySetting {
            items {
              groupID
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
            }
          }
          user {
            receiveNotify
            receiveEmail
            email
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getChatGroup = /* GraphQL */ `
  query GetChatGroup($id: ID!) {
    getChatGroup(id: $id) {
      id
      content
      attachedVideos(limit: 100000) {
        items {
          id
          attachToID
          videoID
          createdAt
          updatedAt
          video {
            title
            status
            thumbnail {
              bucket
              region
              key
            }
            publicToSpace
            publicToGroup(limit: 100000) {
              items {
                id
                groupID
                videoID
                group {
                  name
                }
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      profileID
      groupID
      files {
        bucket
        region
        key
      }
      parentID
      parent {
        id
        content
        profileID
        groupID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          groupID
          parentID
          mentions
          createdAt
          updatedAt
          type
        }
        mentions
        createdAt
        updatedAt
        likes {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      type
      mentions
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listChatGroups = /* GraphQL */ `
  query ListChatGroups($filter: ModelChatGroupFilterInput, $limit: Int, $nextToken: String) {
    listChatGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        attachedVideos(limit: 100000) {
          items {
            id
            attachToID
            videoID
            createdAt
            updatedAt
            video {
              title
              status
              thumbnail {
                bucket
                region
                key
              }
              publicToSpace
              publicToGroup(limit: 100000) {
                items {
                  id
                  groupID
                  videoID
                  group {
                    name
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        profileID
        groupID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          groupID
          parentID
          mentions
          createdAt
          updatedAt
          type
        }
        type
        mentions
        createdAt
        updatedAt
        likes {
          items {
            id
            targetID
            targetType
            profileID
            emoji
            profile {
              id
              name
              avatar {
                bucket
                region
                key
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          avatar {
            bucket
            region
            key
          }
          notifySetting {
            items {
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
              visionBoardSetting
            }
          }
          groupNotifySetting {
            items {
              groupID
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
            }
          }
          user {
            receiveNotify
            receiveEmail
            email
          }
          createdAt
          updatedAt
        }
        issues {
          items {
            id
            targetID
            targetType
            issueID
            profileID
            createdAt
            updatedAt
            issue {
              id
              goalID
              goal {
                id
                visionID
                status
                color
                vision {
                  id
                  status
                }
              }
              status
              title
            }
          }
          nextToken
        }
        goals {
          items {
            id
            targetID
            targetType
            goalID
            profileID
            createdAt
            updatedAt
            goal {
              id
              visionID
              status
              title
              color
              vision {
                id
                status
                coachRoomID
              }
            }
          }
          nextToken
        }
        visions {
          items {
            id
            targetID
            targetType
            visionID
            profileID
            createdAt
            updatedAt
            vision {
              id
              status
              title
              coachRoomID
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      targetID
      targetType
      profileID
      emoji
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        targetID
        targetType
        profileID
        emoji
        createdAt
        updatedAt
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          avatar {
            bucket
            region
            key
          }
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVideoInfo = /* GraphQL */ `
  query GetVideoInfo($id: ID!) {
    getVideoInfo(id: $id) {
      id
      watched
      createdAt
      updatedAt
    }
  }
`;
export const listVideoInfos = /* GraphQL */ `
  query ListVideoInfos(
    $filter: ModelVideoInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        watched
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      title
      description
      status
      thumbnail {
        bucket
        region
        key
      }
      content {
        bucket
        region
        key
      }
      profileID
      spaceID
      inviteCode
      publicToSpace
      duration
      infoID
      publicToGroup {
        items {
          id
          groupID
          videoID
          group {
            joinedMembers {
              items {
                id
                groupID
                userID
              }
              nextToken
            }
            userID
            isCoachGroup
            name
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isShareAble
      type
      videoUrl
      tags
      createdAt
      updatedAt
      space {
        id
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              title
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
          profile {
            userID
            user {
              receiveNotify
              receiveEmail
              email
            }
            notifySetting {
              items {
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
                visionBoardSetting
              }
            }
            groupNotifySetting {
              items {
                groupID
                mentionSetting
                newVideoSetting
                videoCommentSetting
                spaceApplicationSetting
                replySetting
              }
            }
            status
            role
          }
        }
        nextToken
      }
      info {
        id
        watched
        createdAt
        updatedAt
      }
      comments(limit: 10000) {
        items {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
          issues {
            items {
              id
              targetID
              targetType
              issueID
              profileID
              createdAt
              updatedAt
              issue {
                id
                goalID
                goal {
                  id
                  visionID
                  title
                  status
                  color
                  vision {
                    id
                    status
                  }
                }
                status
                title
              }
            }
            nextToken
          }
        }
        nextToken
      }
      guestComments(limit: 10000) {
        items {
          id
        }
        nextToken
      }
      guestComments(limit: 10000) {
        items {
          id
        }
        nextToken
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          items {
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          nextToken
        }
        capacity
        isShareAble
        type
        videoUrl
        tags
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          items {
            id
            profile {
              userID
            }
          }
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          items {
            profile {
              userID
            }
          }
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      mentionContent
      commentAt
      attachments {
        bucket
        region
        key
      }
      profileID
      videoID
      groupID
      parentID
      spaceID
      editors
      mentions
      createdAt
      updatedAt
      issues {
        items {
          id
          targetID
          targetType
          issueID
          profileID
          createdAt
          updatedAt
          issue {
            id
            goalID
            goal {
              id
              visionID
              status
              color
              vision {
                id
                status
              }
            }
            status
            title
          }
        }
        nextToken
      }
      goals {
        items {
          id
          targetID
          targetType
          goalID
          profileID
          createdAt
          updatedAt
          goal {
            id
            visionID
            status
            title
            color
            vision {
              id
              status
              coachRoomID
            }
          }
        }
        nextToken
      }
      visions {
        items {
          id
          targetID
          targetType
          visionID
          profileID
          createdAt
          updatedAt
          vision {
            id
            status
            title
            coachRoomID
          }
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      video {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          items {
            id
            groupID
            videoID
            group {
              joinedMembers {
                items {
                  id
                  groupID
                  userID
                }
                nextToken
              }
              userID
              isCoachGroup
              name
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        likes {
          items {
            id
            targetID
            targetType
            profileID
            emoji
            createdAt
            updatedAt
            profile {
              userID
              user {
                receiveNotify
                receiveEmail
                email
              }
              notifySetting {
                items {
                  mentionSetting
                  newVideoSetting
                  videoCommentSetting
                  spaceApplicationSetting
                  replySetting
                }
              }
              groupNotifySetting {
                items {
                  groupID
                  mentionSetting
                  newVideoSetting
                  videoCommentSetting
                  spaceApplicationSetting
                  replySetting
                }
              }
              status
              role
            }
          }
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      parent {
        id
        content
        commentAt
        attachments {
          bucket
          region
          key
        }
        profileID
        videoID
        groupID
        parentID
        editors
        mentions
        createdAt
        updatedAt
        likes {
          nextToken
        }
        video {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          createdAt
          updatedAt
        }
        parent {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments($filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        mentionContent
        commentAt
        type
        owner
        attachedVideos(limit: 100000) {
          items {
            id
            attachToID
            videoID
            createdAt
            updatedAt
            video {
              title
              status
              thumbnail {
                bucket
                region
                key
              }
              publicToSpace
              publicToGroup(limit: 100000) {
                items {
                  id
                  groupID
                  videoID
                  group {
                    name
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        attachments {
          bucket
          region
          key
        }
        profileID
        videoID
        groupID
        parentID
        spaceID
        editors
        mentions
        createdAt
        updatedAt
        issues {
          items {
            id
            targetID
            targetType
            issueID
            profileID
            createdAt
            updatedAt
            issue {
              id
              goalID
              goal {
                id
                visionID
                status
                color
                vision {
                  id
                  status
                }
              }
              status
              title
            }
          }
          nextToken
        }
        goals {
          items {
            id
            targetID
            targetType
            goalID
            profileID
            createdAt
            updatedAt
            goal {
              id
              visionID
              status
              title
              color
              vision {
                id
                status
                coachRoomID
              }
            }
          }
          nextToken
        }
        visions {
          items {
            id
            targetID
            targetType
            visionID
            profileID
            createdAt
            updatedAt
            vision {
              id
              status
              title
              coachRoomID
            }
          }
          nextToken
        }
        likes {
          items {
            id
            targetID
            targetType
            profileID
            createdAt
            updatedAt
            emoji
            profile {
              id
              name
              avatar {
                bucket
                region
                key
              }
            }
          }
          nextToken
        }
        video {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          createdAt
          updatedAt
        }
        parent {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          mentions
          createdAt
          updatedAt
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
          avatar {
            bucket
            region
            key
          }
          notifySetting {
            items {
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
              visionBoardSetting
            }
          }
          groupNotifySetting {
            items {
              groupID
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
            }
          }
          user {
            receiveNotify
            receiveEmail
            email
            isDisabled
            isDeleted
            isUnregistered
            isReadyDelete
            fcmToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      isSpaceCoach
      name
      career
      avatar {
        bucket
        region
        key
      }
      description
      userID
      spaceID
      user {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        fcmToken
        groups {
          nextToken
        }
        joinedGroups {
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      notifySetting {
        items {
          id
          profileID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          visionBoardSetting
          createdAt
          updatedAt
        }
        nextToken
      }
      confirmed
      status
      role
      reason
      groupNotifySetting {
        items {
          id
          profileID
          groupID
          mentionSetting
          newVideoSetting
          videoCommentSetting
          spaceApplicationSetting
          replySetting
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        inviteCode
        userID
        editors
        members
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        public {
          nextToken
        }
        type
        status
        planID
        planName
        maxMember
        currentUpload
        currentStorage
        monthlyUpload
        maxStorage
        paymentFee
        paymentCycle
        paymentMethod
        memo
        createdAt
        updatedAt
        videos {
          nextToken
        }
        profiles {
          nextToken
        }
        joinSpaceKey {
          nextToken
        }
      }
      filterVisionMessage {
        myAction
        someOneElseAction
      }
    }
  }
`;
export const listProfilesPublic = /* GraphQL */ `
  query ListProfiles($filter: ModelProfileFilterInput, $limit: Int, $nextToken: String) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isSpaceCoach
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        confirmed
        status
        role
        reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles($filter: ModelProfileFilterInput, $limit: Int, $nextToken: String) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isSpaceCoach
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
          joinedGroups {
            items {
              id
              spaceID
            }
          }
        }
        notifySetting {
          items {
            id
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
            visionBoardSetting
          }
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          items {
            id
            groupID
            mentionSetting
            newVideoSetting
            videoCommentSetting
            spaceApplicationSetting
            replySetting
          }
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      spaceID
      groupID
      videoID
      messageID
      visionID
      goalID
      issueID
      profileID
      receiverID
      type
      status
      statusBadge
      fromGroups
      userID
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          fcmToken
          type
          memo
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        spaceID
        groupID
        videoID
        messageID
        visionID
      goalID
      issueID
        profileID
        receiverID
        type
        status
        statusBadge
        fromGroups
        userID
        createdAt
        updatedAt
        profile {
          id
          name
          career
          avatar {
            bucket
            region
            key
          }
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSpaceNotificationSetting = /* GraphQL */ `
  query GetSpaceNotificationSetting($id: ID!) {
    getSpaceNotificationSetting(id: $id) {
      id
      profileID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      visionBoardSetting
      createdAt
      updatedAt
    }
  }
`;
export const listSpaceNotificationSettings = /* GraphQL */ `
  query ListSpaceNotificationSettings(
    $filter: ModelSpaceNotificationSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaceNotificationSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileID
        mentionSetting
        newVideoSetting
        videoCommentSetting
        spaceApplicationSetting
        replySetting
        visionBoardSetting
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJoinSpaceKey = /* GraphQL */ `
  query GetJoinSpaceKey($id: ID!) {
    getJoinSpaceKey(id: $id) {
      id
      spaceID
      userName
      spaceName
      content
      avatarKey
      requestRole
      keyAccess
      space {
        id
        name
        description
        avatar {
          bucket
          region
          key
        }
        editors
        members
        planID
        maxMember
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJoinSpaceKeys = /* GraphQL */ `
  query ListJoinSpaceKeys(
    $filter: ModelJoinSpaceKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJoinSpaceKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        spaceID
        userName
        spaceName
        content
        avatarKey
        requestRole
        keyAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGuestComment = /* GraphQL */ `
  query GetGuestComment($id: ID!) {
    getGuestComment(id: $id) {
      id
      name
      content
      type
      commentAt
      accessKeyId
      secretAccessKey
      sessionToken
      parentID
      videoID
      createdAt
      updatedAt
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listGuestComments = /* GraphQL */ `
  query ListGuestComments($filter: ModelGuestCommentFilterInput, $limit: Int, $nextToken: String) {
    listGuestComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        content
        type
        commentAt
        accessKeyId
        secretAccessKey
        sessionToken
        parentID
        videoID
        createdAt
        updatedAt
        issues {
          items {
            id
            targetID
            targetType
            issueID
            profileID
            createdAt
            updatedAt
            issue {
              id
              goalID
              goal {
                id
                visionID
                status
                color
                vision {
                  id
                  status
                }
              }
              status
              title
            }
          }
          nextToken
        }
        goals {
          items {
            id
            targetID
            targetType
            goalID
            profileID
            createdAt
            updatedAt
            goal {
              id
              visionID
              status
              title
              color
              vision {
                id
                status
                coachRoomID
              }
            }
          }
          nextToken
        }
        visions {
          items {
            id
            targetID
            targetType
            visionID
            profileID
            createdAt
            updatedAt
            vision {
              id
              status
              title
              coachRoomID
            }
          }
          nextToken
        }
        likes {
          items {
            id
            targetID
            targetType
            profileID
            createdAt
            updatedAt
            emoji
            profile {
              id
              name
              avatar {
                bucket
                region
                key
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getVideoGuest = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      title
      description
      status
      thumbnail {
        bucket
        region
        key
      }
      content {
        bucket
        region
        key
      }
      isShareAble
      type
      videoUrl
      tags
      profileID
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        createdAt
        updatedAt
      }
      spaceID
      space {
        editors
      }
      inviteCode
      publicToSpace
      comments {
        items {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          targetID
          targetType
          profileID
          emoji
          createdAt
          updatedAt
        }
        nextToken
      }
      duration
      infoID
      info {
        id
        watched
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const listCommentsGuest = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        mentionContent
        commentAt
        type
        owner
        attachedVideos(limit: 100000) {
          items {
            id
            attachToID
            videoID
            createdAt
            updatedAt
            video {
              title
              status
              thumbnail {
                bucket
                region
                key
              }
              publicToSpace
              publicToGroup(limit: 100000) {
                items {
                  id
                  groupID
                  videoID          
                  group {
                    name
                  }          
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        attachments {
          bucket
          region
          key
        }
        profileID
        videoID
        groupID
        parentID
        editors
        profile {
          id
          name
          career
          description
          userID
          spaceID
          createdAt
          updatedAt
          avatar {
            bucket
            region
            key
          }
        }
        video {
          id
          title
          description
          status
          profileID
          spaceID
          inviteCode
          publicToSpace
          duration
          infoID
          createdAt
          updatedAt
        }
        parent {
          id
          content
          commentAt
          profileID
          videoID
          groupID
          parentID
          editors
          createdAt
          updatedAt
        }
        likes {
          items {
            id
            targetID
            targetType
            profileID
            createdAt
            updatedAt
            emoji
            profile {
              id
              name
              avatar {
                bucket
                region
                key
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupNotificationSetting = /* GraphQL */ `
  query GetGroupNotificationSetting($id: ID!) {
    getGroupNotificationSetting(id: $id) {
      id
      profileID
      groupID
      mentionSetting
      newVideoSetting
      videoCommentSetting
      spaceApplicationSetting
      replySetting
      createdAt
      updatedAt
    }
  }
`;
export const listGroupNotificationSettings = /* GraphQL */ `
  query ListGroupNotificationSettings(
    $filter: ModelGroupNotificationSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupNotificationSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        groupID
        mentionSetting
        newVideoSetting
        videoCommentSetting
        spaceApplicationSetting
        replySetting
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlanMaster = /* GraphQL */ `
  query GetPlanMaster($id: ID!) {
    getPlanMaster(id: $id) {
      id
      name
      description
      maxMember
      monthlyUpload
      maxStorage
      monthlyFee
      yearlyFee
      createdAt
      updatedAt
      monthlyYoutubeUpload
      totalYoutubeUpload
    }
  }
`;
export const listPlanMasters = /* GraphQL */ `
  query ListPlanMasters(
    $filter: ModelPlanMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        maxMember
        monthlyUpload
        maxStorage
        monthlyFee
        yearlyFee
        createdAt
        updatedAt
        monthlyYoutubeUpload
        totalYoutubeUpload
      }
      nextToken
    }
  }
`;
export const getPaymentHistory = /* GraphQL */ `
  query GetPaymentHistory($id: ID!) {
    getPaymentHistory(id: $id) {
      id
      spaceID
      userID
      spaceName
      accountName
      paymentPackage
      productName
      amount
      maxMember
      monthlyUpload
      maxStorage
      totalFee
      invoiceDate
      paymentDate
      paymentPeriod
      paymentCycle
      expireDate
      paymentMethod
      status
      memo
      payJPPaymentID
      payJPSubscriptionID
      billStatus
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentHistorys = /* GraphQL */ `
  query ListPaymentHistorys(
    $filter: ModelPaymentHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        spaceID
        userID
        spaceName
        accountName
        paymentPackage
        productName
        amount
        maxMember
        monthlyUpload
        maxStorage
        totalFee
        invoiceDate
        paymentCycle
        paymentDate
        paymentPeriod
        expireDate
        paymentMethod
        status
        memo
        payJPPaymentID
        payJPSubscriptionID
        billStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAccount = /* GraphQL */ `
  query GetUserAccount($id: ID!) {
    getUserAccount(id: $id) {
      id
      userID
      email
      password
        oAuthUserRef
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserAccounts = /* GraphQL */ `
  query ListUserAccounts(
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        email
        password
        oAuthUserRef
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getEmailCycle = /* GraphQL */ `
  query GetEmailCycle($id: ID!) {
    getEmailCycle(id: $id) {
      id
      typeCycle
      content
      email
      receiverName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEmailCycles = /* GraphQL */ `
  query ListEmailCycles(
    $filter: ModelEmailCycleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailCycles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeCycle
        content
        email
        receiverName
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;



export const spaceChatByCreatedAt = /* GraphQL */ `
  query SpaceChatByCreatedAt(
    $spaceID: ID
    $parentIDCreatedAt: ModelChatSpaceChatByCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spaceChatByCreatedAt(
      spaceID: $spaceID
      parentIDCreatedAt: $parentIDCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        title
        mentionContent
        attachedVideos(limit: 100000) {
          items {
            id
            attachToID
            videoID
            createdAt
            updatedAt
            video {
              title
              status
              thumbnail {
                bucket
                region
                key
              }
              publicToSpace
              publicToGroup(limit: 100000) {
                items {
                  id
                  groupID
                  videoID
                  group {
                    name
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        profileID
        spaceID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          spaceID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        mentions
        type
        createdAt
        child(limit: 100000) {
          items {
            id
            content
            profileID
            spaceID
            parentID
            mentions
            mentionContent
            type
            createdAt
            updatedAt
            likes {
              items {
                id
                targetID
                targetType
                profileID
                profile {
                  id
                  name
                  avatar {
                    bucket
                    region
                    key
                  }
                }
                emoji
                createdAt
                updatedAt
              }
              nextToken
            }
            profile {
              id
              name
              career
              description
              userID
              spaceID
              confirmed
              status
              role
              reason
              avatar {
                bucket
                region
                key
              }
              notifySetting {
                items {
                  mentionSetting
                  newVideoSetting
                  videoCommentSetting
                  spaceApplicationSetting
                  replySetting
                  visionBoardSetting
                }
              }
              groupNotifySetting {
                items {
                  groupID
                  mentionSetting
                  newVideoSetting
                  videoCommentSetting
                  spaceApplicationSetting
                  replySetting
                }
              }
              user {
                receiveNotify
                receiveEmail
                email
              }
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        updatedAt
        issues {
          items {
            id
            targetID
            targetType
            issueID
            profileID
            createdAt
            updatedAt
            issue {
              id
              goalID
              goal {
                id
                visionID
                status
                color
                vision {
                  id
                  status
                }
              }
              status
              title
            }
          }
          nextToken
        }
        goals {
          items {
            id
            targetID
            targetType
            goalID
            profileID
            createdAt
            updatedAt
            goal {
              id
              visionID
              status
              title
              color
              vision {
                id
                status
                coachRoomID
              }
            }
          }
          nextToken
        }
        visions {
          items {
            id
            targetID
            targetType
            visionID
            profileID
            createdAt
            updatedAt
            vision {
              id
              status
              title
              coachRoomID
            }
          }
          nextToken
        }
        likes {
          items {
            id
            targetID
            targetType
            profileID
            profile {
              id
              name
              avatar {
                bucket
                region
                key
              }
            }
            emoji
            createdAt
            updatedAt
          }
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          avatar {
            bucket
            region
            key
          }
          notifySetting {
            items {
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
              visionBoardSetting
            }
          }
          groupNotifySetting {
            items {
              groupID
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
            }
          }
          user {
            receiveNotify
            receiveEmail
            email
          }
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const groupChatByCreatedAt = /* GraphQL */ `
  query GroupChatByCreatedAt(
    $groupID: ID
    $parentIDCreatedAt: ModelChatGroupGroupChatByCreatedAtCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupChatByCreatedAt(
      groupID: $groupID
      parentIDCreatedAt: $parentIDCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        title
        mentionContent
        visionActionPerformer
        attachedVideos(limit: 100000) {
          items {
            id
            attachToID
            videoID
            createdAt
            updatedAt
            video {
              title
              status
              thumbnail {
                bucket
                region
                key
              }
              publicToSpace
              publicToGroup(limit: 100000) {
                items {
                  id
                  groupID
                  videoID
                  group {
                    name
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
          }
          nextToken
        }
        profileID
        groupID
        files {
          bucket
          region
          key
        }
        parentID
        parent {
          id
          content
          profileID
          groupID
          parentID
          mentions
          type
          createdAt
          updatedAt
        }
        mentions
        type
        createdAt
        child(limit: 100000) {
          items {
            id
            content
            profileID
            groupID
            parentID
            mentions
            mentionContent
            type
            createdAt
            updatedAt
            likes {
              items {
                id
                targetID
                targetType
                profileID
                profile {
                  id
                  name
                  avatar {
                    bucket
                    region
                    key
                  }
                }
                emoji
                createdAt
                updatedAt
              }
              nextToken
            }
            profile {
              id
              name
              career
              description
              userID
              spaceID
              confirmed
              status
              role
              reason
              avatar {
                bucket
                region
                key
              }
              notifySetting {
                items {
                  mentionSetting
                  newVideoSetting
                  videoCommentSetting
                  spaceApplicationSetting
                  replySetting
                  visionBoardSetting
                }
              }
              groupNotifySetting {
                items {
                  groupID
                  mentionSetting
                  newVideoSetting
                  videoCommentSetting
                  spaceApplicationSetting
                  replySetting
                }
              }
              user {
                receiveNotify
                receiveEmail
                email
              }
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        updatedAt
        likes {
          items {
            id
            targetID
            targetType
            profileID
            profile {
              id
              name
              avatar {
                bucket
                region
                key
              }
            }
            emoji
            createdAt
            updatedAt
          }
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          avatar {
            bucket
            region
            key
          }
          notifySetting {
            items {
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
              visionBoardSetting
            }
          }
          groupNotifySetting {
            items {
              groupID
              mentionSetting
              newVideoSetting
              videoCommentSetting
              spaceApplicationSetting
              replySetting
            }
          }
          user {
            receiveNotify
            receiveEmail
            email
          }
          createdAt
          updatedAt
        }
        issues {
          items {
            id
            targetID
            targetType
            issueID
            profileID
            createdAt
            updatedAt
            issue {
              id
              goalID
              goal {
                id
                title
                visionID
                status
                color
                vision {
                  id
                  status
                  coachRoomID
                }
              }
              status
              title
            }
          }
          nextToken
        }
        goals {
          items {
            id
            targetID
            targetType
            goalID
            profileID
            createdAt
            updatedAt
            goal {
              id
              visionID
              status
              title
              color
              vision {
                id
                status
                coachRoomID
              }
            }
          }
          nextToken
        }
        visions {
          items {
            id
            targetID
            targetType
            visionID
            profileID
            createdAt
            updatedAt
            vision {
              id
              status
              title
              coachRoomID
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSpaceInvitation = /* GraphQL */ `
  query GetSpaceInvitation($id: ID!) {
    getSpaceInvitation(id: $id) {
      id
      spaceID
      userID
      email
      role
      createdAt
      updatedAt
    }
  }
`;
export const listSpaceInvitations = /* GraphQL */ `
  query ListSpaceInvitations(
    $filter: ModelSpaceInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpaceInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        spaceID
        userID
        email
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserUnfinish = /* GraphQL */ `
  query GetUserUnfinish($id: ID!) {
    getUserUnfinish(id: $id) {
      id
      email
    }
  }
`;
export const listUserUnfinishs = /* GraphQL */ `
  query ListUserUnfinishs(
    $filter: ModelUserUnfinishFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserUnfinishs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
      }
      nextToken
    }
  }
`;

export const userByCreatedAt = /* GraphQL */ `
  query userByCreatedAt(
    $queryType: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCreatedAt(
      queryType: $queryType
      sortDirection: $sortDirection
      filter: $filter, 
      limit: $limit, 
      nextToken: $nextToken
    ) {
      items {
        id
        name
        birthday
        gender
        address
        phoneNumber
        career
        avatar {
          bucket
          region
          key
        }
        description
        email
        receiveNotify
        receiveEmail
        receiveEmailCycle
        isDisabled
        isDeleted
        isUnregistered
        isReadyDelete
        fcmToken
        groups {
          nextToken
        }
        joinedGroups {
          items {
            id
            groupID
            userID
            spaceID
            createdAt
            updatedAt
          }
          nextToken
        }
        type
        memo
        createdAt
        updatedAt
        spaces {
          nextToken
        }
        profiles {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPayJPSubscription = /* GraphQL */ `
  query GetPayJpSubscription($id: ID!) {
    getPayJPSubscription(id: $id) {
      id
      userID
      spaceID
      productType
      productName
      quantity
      amount
      startEffectDate
      paymentCycle
      payJPPlanID
      payJPSubscriptionID
      createdAt
      updatedAt
      currentPeriodStart
      currentPeriodEnd
      status
      user {
        id
        name
      }
    }
  }
`;
export const listPayJPSubscriptions = /* GraphQL */ `
  query ListPayJpSubscriptions(
    $filter: ModelPayJPSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayJPSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        spaceID
        productType
        productName
        quantity
        amount
        startEffectDate
        paymentCycle
        payJPPlanID
        payJPSubscriptionID
        createdAt
        updatedAt
        currentPeriodStart
        currentPeriodEnd
        status
        user {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listBySpaceID = /* GraphQL */ `
  query ListBySpaceId(
    $spaceID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayJPSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBySpaceID(
      spaceID: $spaceID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        spaceID
        productType
        productName
        quantity
        amount
        startEffectDate
        paymentCycle
        payJPPlanID
        payJPSubscriptionID
        createdAt
        updatedAt
        currentPeriodStart
        currentPeriodEnd
        status
        user {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listByUserID = /* GraphQL */ `
  query listByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayJPSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        spaceID
        productType
        productName
        quantity
        amount
        startEffectDate
        paymentCycle
        payJPPlanID
        payJPSubscriptionID
        createdAt
        updatedAt
        currentPeriodStart
        currentPeriodEnd
        status
        user {
          id
          name
        }
        space {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listBySpaceIDProductType = /* GraphQL */ `
  query ListBySpaceIdProductType(
    $spaceID: ID
    $productTypeCreatedAt: ModelPayJPSubscriptionListBySpaceIDProductTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayJPSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBySpaceIDProductType(
      spaceID: $spaceID
      productTypeCreatedAt: $productTypeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        spaceID
        productType
        productName
        quantity
        amount
        startEffectDate
        paymentCycle
        payJPPlanID
        payJPSubscriptionID
        createdAt
        updatedAt
        currentPeriodStart
        currentPeriodEnd
        status
        user {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listBySpaceIDProductName = /* GraphQL */ `
  query ListBySpaceIdProductName(
    $spaceID: ID
    $productNameCreatedAt: ModelPayJPSubscriptionListBySpaceIDProductNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayJPSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBySpaceIDProductName(
      spaceID: $spaceID
      productNameCreatedAt: $productNameCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        spaceID
        productType
        productName
        quantity
        amount
        startEffectDate
        paymentCycle
        payJPPlanID
        payJPSubscriptionID
        createdAt
        updatedAt
        currentPeriodStart
        currentPeriodEnd
        status
        user {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listBySpaceIDStatus = /* GraphQL */ `
  query ListBySpaceIdStatus(
    $spaceID: ID
    $statusCreatedAt: ModelPaymentHistoryListBySpaceIDStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBySpaceIDStatus(
      spaceID: $spaceID
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        spaceID
        userID
        spaceName
        accountName
        paymentPackage
        productName
        amount
        maxMember
        monthlyUpload
        maxStorage
        totalFee
        invoiceDate
        paymentDate
        paymentCycle
        paymentPeriod
        expireDate
        paymentMethod
        status
        memo
        payJPPaymentID
        payJPSubscriptionID
        billStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdminNotification = /* GraphQL */ `
  query GetAdminNotification($id: ID!) {
    getAdminNotification(id: $id) {
      id
      targetID
      body
      type
      startedAt
      startedAtFormat
      endedAt
      endedAtFormat
      createdAt
      updatedAt
    }
  }
`;
export const listAdminNotifications = /* GraphQL */ `
  query ListAdminNotifications(
    $filter: ModelAdminNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetID
        body
        type
        startedAt
        startedAtFormat
        endedAt
        endedAtFormat
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const adminNotificationByStartedAt = /* GraphQL */ `
  query AdminNotificationByStartedAt(
    $targetID: String
    $startedAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminNotificationByStartedAt(
      targetID: $targetID
      startedAt: $startedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetID
        body
        type
        startedAt
        startedAtFormat
        endedAt
        endedAtFormat
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      description
      type
      spaceID
      parentID
      parent {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        child {
          nextToken
        }
        createdAt
        updatedAt
      }
      child {
        items {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagsBySpaceAndTypeAndParent = /* GraphQL */ `
  query TagsBySpaceAndTypeAndParent(
    $spaceID: ID
    $typeParentID: ModelTagTagsBySpaceAndTypeAndParentCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsBySpaceAndTypeAndParent(
      spaceID: $spaceID
      typeParentID: $typeParentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        spaceID
        parentID
        parent {
          id
          name
          description
          type
          spaceID
          parentID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bySpaceIDCreatedAt = /* GraphQL */ `
  query BySpaceIdCreatedAt(
    $spaceID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySpaceIDCreatedAt(
      spaceID: $spaceID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        status
        thumbnail {
          bucket
          region
          key
        }
        content {
          bucket
          region
          key
        }
        profileID
        spaceID
        inviteCode
        publicToSpace
        duration
        infoID
        publicToGroup {
          items {
            id
            groupID
            videoID
            group {
              id
              joinedMembers {
                items {
                  id
                  groupID
                  userID
                }
                nextToken
              }
              userID
              isCoachGroup
              name
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        capacity
        isShareAble
        type
        videoUrl
        tags
        createdAt
        updatedAt
        space {
          id
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          createdAt
          updatedAt
        }
        issues {
          items {
            id
            targetID
            targetType
            issueID
            profileID
            createdAt
            updatedAt
            issue {
              id
              goalID
              goal {
                id
                visionID
                title
                status
                color
                vision {
                  id
                  status
                }
              }
              status
              title
            }
          }
          nextToken
        }
        goals {
          items {
            id
            targetID
            targetType
            goalID
            profileID
            createdAt
            updatedAt
            goal {
              id
              visionID
              status
              title
              color
              vision {
                id
                status
                coachRoomID
              }
            }
          }
          nextToken
        }
        visions {
          items {
            id
            targetID
            targetType
            visionID
            profileID
            createdAt
            updatedAt
            vision {
              id
              status
              title
              coachRoomID
            }
          }
          nextToken
        }
        likes {
          items {
            id
            profile {
              userID
            }
          }
          nextToken
        }
        info {
          id
          watched
          createdAt
          updatedAt
        }
        comments(limit: 10000) {
          items {
            profile {
              userID
            }
            issues {
              items {
                id
                targetID
                targetType
                issueID
                profileID
                createdAt
                updatedAt
                issue {
                  id
                  goalID
                  goal {
                    id
                    visionID
                    title
                    status
                    color
                    vision {
                      id
                      status
                    }
                  }
                  status
                  title
                }
              }
              nextToken
            }
          }
          nextToken
        }
        guestComments(limit: 10000) {
          items {
            id
          }
          nextToken
        }
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCoachRoom = /* GraphQL */ `
  query GetCoachRoom($id: ID!) {
    getCoachRoom(id: $id) {
      id
      spaceID
      groupID
      name
      description
      profileID
      coachs
      sharedGroups
      visions {
        items {
          id
          title
          description
          status
          coachs
          coachRoomID
          profileID
          isPublic
          files {
            bucket
            region
            key
          }
          isSetBackground
          background {
            bucket
            region
            key
          }
          totalGoals
          createdAt
          archivedAt
          goals {
            items {
              id
              visionID
              title
              color
              startDate
              order
              endDate
              status
              coachs
              createdAt
              issues {
                items {
                  id
                  goalID
                  goal {
                    id
                    visionID
                    title
                    status
                    color
                    vision {
                      id
                      coachRoomID
                      status
                    }
                  }
                  progress
                  title
                  startDate
                  endDate
                  status
                  coachs
                  createdAt
                  updatedAt
                }
              }
              updatedAt
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCoachRooms = /* GraphQL */ `
  query ListCoachRooms($filter: ModelCoachRoomFilterInput, $limit: Int, $nextToken: String) {
    listCoachRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        spaceID
        groupID
        name
        description
        profileID
        coachs
        sharedGroups
        visions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVision = /* GraphQL */ `
  query GetVision($id: ID!) {
    getVision(id: $id) {
      id
      title
      description
      status
      coachs
      coachRoomID
      profileID
      isPublic
      isSetBackground
      totalGoals
      background {
        bucket
        region
        key
      }
      files {
        bucket
        region
        key
      }
      goals {
        items {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          coachs
          createdAt
          issues {
            items {
              id
              goalID
              goal {
                id
                visionID
                title
                status
                color
                vision {
                  id
                  coachRoomID
                  status
                }
              }
              progress
              title
              startDate
              endDate
              status
              coachs
              createdAt
              updatedAt
            }
          }
          updatedAt
        }
      }
    }
  }
`;
export const listVisions = /* GraphQL */ `
  query ListVisions($filter: ModelVisionFilterInput, $limit: Int, $nextToken: String) {
    listVisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        coachRoomID
        title
        description
        profileID
        isPublic
        isSetBackground
        totalGoals
        background {
          bucket
          region
          key
        }
        files {
          bucket
          region
          key
        }
        status
        coachs
        createdAt
        coachRoom {
          id
          spaceID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      visionID
      title
      color
      startDate
      order
      endDate
      status
      coachs
      createdAt
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachRoom {
          id
          spaceID
          name
          description
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      issues {
        items {
          id
          goalID
          title
          startDate
          endDate
          status
          progress
          createdAt
          updatedAt
        }
        nextToken
      }
      links {
        items {
          id
        }
      }
      updatedAt
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals($filter: ModelGoalFilterInput, $limit: Int, $nextToken: String) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        coachs
        createdAt
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIssue = /* GraphQL */ `
  query GetIssue($id: ID!) {
    getIssue(id: $id) {
      id
      goalID
      title
      startDate
      endDate
      status
      createdAt
      progress
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        coachs
        createdAt
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      links {
        items {
          id
        }
      }
      updatedAt
    }
  }
`;
export const listIssues = /* GraphQL */ `
  query ListIssues($filter: ModelIssueFilterInput, $limit: Int, $nextToken: String) {
    listIssues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        goalID
        title
        startDate
        endDate
        status
        coachs
        createdAt
        progress
        goal {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const coachRoomBySpace = /* GraphQL */ `
  query CoachRoomBySpace(
    $spaceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCoachRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coachRoomBySpace(
      spaceID: $spaceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        spaceID
        name
        description
        profileID
        groupID
        coachs
        sharedGroups
        visions {
          items {
            id
            title
            description
            status
            coachs
            coachRoomID
            profileID
            isPublic
            isSetBackground
            totalGoals
            background {
              bucket
              region
              key
            }
            files {
              bucket
              region
              key
            }
            createdAt
            archivedAt
            goals {
              items {
                id
                visionID
                title
                color
                startDate
                order
                endDate
                status
                coachs
                createdAt
                issues {
                  items {
                    id
                    goalID
                    goal {
                      id
                      visionID
                      title
                      status
                      color
                      vision {
                        id
                        coachRoomID
                        status
                      }
                    }
                    title
                    startDate
                    endDate
                    status
                    coachs
                    progress
                    createdAt
                    updatedAt
                  }
                }
                updatedAt
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getIssueLink = /* GraphQL */ `G
  query GetIssueLink($id: ID!) {
    getIssueLink(id: $id) {
      id
      targetID
      targetType
      issueID
      profileID
      issue {
        id
        goalID
        title
        startDate
        endDate
        status
        createdAt
        coachs
        progress
        goal {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          coachs
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listIssueLinks = /* GraphQL */ `
  query ListIssueLinks($filter: ModelIssueLinkFilterInput, $limit: Int, $nextToken: String) {
    listIssueLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        targetID
        targetType
        issueID
        profileID
        issue {
          id
          goalID
          title
          startDate
          endDate
          status
          progress
          goal {
            status
            color
            title
            vision {
              coachRoomID
              status
            }
          }
          createdAt
          coachs
          updatedAt
        }
        createdAt
        updatedAt
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          isSpaceCoach
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const issueLinksByObject = /* GraphQL */ `
  query IssueLinksByObject(
    $targetID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelIssueLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    issueLinksByObject(
      targetID: $targetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetID
        targetType
        issueID
        profileID
        issue {
          id
          goalID
          title
          startDate
          endDate
          status
          progress
          goal {
            id
            status
            title
            color
            visionID
            vision {
              id
              coachRoomID
              status
              title
            }
          }
          createdAt
          coachs
          updatedAt
        }
        createdAt
        updatedAt
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          isSpaceCoach
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGoalLink = /* GraphQL */ `
  query GetGoalLink($id: ID!) {
    getGoalLink(id: $id) {
      id
      targetID
      targetType
      goalID
      profileID
      goal {
        id
        visionID
        title
        color
        startDate
        order
        endDate
        status
        createdAt
        coachs
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        issues {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listGoalLinks = /* GraphQL */ `
  query ListGoalLinks($filter: ModelGoalLinkFilterInput, $limit: Int, $nextToken: String) {
    listGoalLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        targetID
        targetType
        goalID
        profileID
        goal {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          coachs
          updatedAt
        }
        createdAt
        updatedAt
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          isSpaceCoach
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const goalLinksByObject = /* GraphQL */ `
  query GoalLinksByObject(
    $targetID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGoalLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalLinksByObject(
      targetID: $targetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetID
        targetType
        goalID
        profileID
        goal {
          id
          visionID
          title
          color
          startDate
          order
          endDate
          status
          createdAt
          coachs
          updatedAt
          vision {
            id
            coachRoomID
            title
            status
          }
        }
        createdAt
        updatedAt
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          isSpaceCoach
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVisionLink = /* GraphQL */ `
  query GetVisionLink($id: ID!) {
    getVisionLink(id: $id) {
      id
      targetID
      targetType
      visionID
      profileID
      vision {
        id
        coachRoomID
        title
        description
        files {
          bucket
          region
          key
        }
        status
        createdAt
        coachs
        profileID
        isPublic
        archivedAt
        coachRoom {
          id
          spaceID
          groupID
          profileID
          name
          description
          coachs
          sharedGroups
          createdAt
          updatedAt
        }
        goals {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      profile {
        id
        name
        career
        avatar {
          bucket
          region
          key
        }
        description
        userID
        spaceID
        user {
          id
          name
          birthday
          gender
          address
          phoneNumber
          career
          description
          email
          receiveNotify
          receiveEmail
          receiveEmailCycle
          isDisabled
          isDeleted
          isUnregistered
          isReadyDelete
          type
          memo
          fcmToken
          queryType
          createdAt
          updatedAt
        }
        notifySetting {
          nextToken
        }
        confirmed
        status
        role
        reason
        groupNotifySetting {
          nextToken
        }
        isEditOnce
        isSpaceCoach
        visions {
          nextToken
        }
        createdAt
        updatedAt
        space {
          id
          name
          description
          inviteCode
          userID
          editors
          members
          type
          status
          planID
          planName
          dayLimitation
          maxMember
          currentUpload
          currentStorage
          monthlyUpload
          maxStorage
          paymentFee
          paymentCycle
          paymentMethod
          memo
          monthlyYoutubeUpload
          totalYoutubeUpload
          isListMemberPublic
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listVisionLinks = /* GraphQL */ `
  query ListVisionLinks($filter: ModelVisionLinkFilterInput, $limit: Int, $nextToken: String) {
    listVisionLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        targetID
        targetType
        visionID
        profileID
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        createdAt
        updatedAt
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          isSpaceCoach
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const visionLinksByObject = /* GraphQL */ `
  query VisionLinksByObject(
    $targetID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVisionLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    visionLinksByObject(
      targetID: $targetID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        targetID
        targetType
        visionID
        profileID
        vision {
          id
          coachRoomID
          title
          description
          status
          createdAt
          coachs
          profileID
          isPublic
          archivedAt
          updatedAt
        }
        createdAt
        updatedAt
        profile {
          id
          name
          career
          description
          userID
          spaceID
          confirmed
          status
          role
          reason
          isEditOnce
          isSpaceCoach
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;