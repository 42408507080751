export const state = () => ({
  currentGroup: {},
  currentGroups: {},
});

export const mutations = {
  SET_GROUP(state, group) {
    state.currentGroup = group;
  },
  SET_GROUPS(state, groups) {
    state.currentGroups = groups;
  },
};

export const actions = {
  async getListGroups({ commit, dispatch }, { filter, limit, nextToken }) {
    try {
      const groupData = await dispatch(
        'api/queryAll',
        { query: 'listGroups', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_GROUPS', groupData);
      return groupData;
    } catch (error) {
      console.error('fail to list Group', error);
      return false;
    }
  },

  async listJoinGroups({ dispatch }, { filter, limit, nextToken }) {
    try {
      return await dispatch(
        'api/queryAll',
        { query: 'listJoinGroups', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to list JoinGroup', error);
      return false;
    }
  },

  async getListPublicGroups({ dispatch }, { filter, limit, nextToken }) {
    try {
      return await dispatch(
        'api/query',
        { query: 'listPublicGroups', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to list Public Group', error);
      return false;
    }
  },

  async getGroup({ commit, dispatch }, id) {
    try {
      const groupData = await dispatch('api/get', { query: 'getGroup', id }, { root: true });
      commit('SET_GROUP', groupData);
      return groupData;
    } catch (error) {
      console.error('fail to get Group', error);
      return false;
    }
  },

  async createGroup({ commit, dispatch }, input) {
    try {
      const groupData = await dispatch(
        'api/mutate',
        { mutation: 'createGroup', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_GROUP', groupData);
      return groupData;
    } catch (error) {
      console.error('fail to create Group', error);
      return false;
    }
  },

  async createGroupOnly({ dispatch }, input) {
    try {
      const groupData = await dispatch(
        'api/mutate',
        { mutation: 'createGroup', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      return groupData;
    } catch (error) {
      console.error('fail to create Group', error);
      return false;
    }
  },
  async createVisionGroup({ dispatch }, input) {
    const { name, userID, spaceID, profileID, ownerProfileID } = input;
    const groupData = await dispatch('createGroupOnly', {
      name,
      userID,
      spaceID,
      isCoachGroup: true,
    });
    console.log(groupData);
    if (groupData) {
      const visionLink = `<a href="/space/${spaceID}/group/${groupData.id}/vision_board" class="has-text-link has-text-underline">こちら</a>`;
      const topLink =
        '<a target="_blank" href="/vision_board_user_guide?forceNoRedirect=true" class="has-text-link has-text-underline">こちら</a>';
      const newGroupMessage = {
        title: 'ナレッジ・ループからのご案内',
        content: `
            ようこそビジョンボードへ！ <br />
            ビジョンボードではあなたのビジョンを実現するためのステップを管理できます。<br />
            まずビジョンを作成しましょう。作成は${visionLink}から <br />
            ヘルプページは${topLink}
            `,
        groupID: groupData.id,
        profileID,
        parentID: 'true',
        type: 'system',
      };
      console.log(profileID);
      dispatch('chatGroup/createChatGroup', newGroupMessage, { root: true });
      dispatch(
        'coachRoom/createCoachRoom',
        {
          spaceID,
          groupID: groupData.id,
          profileID,
          coachs: [ownerProfileID],
        },
        { root: true } // eslint-disable-line comma-dangle
      );
      dispatch('joinGroup', {
        groupID: groupData.id,
        userID,
        spaceID,
      });
    }
  },
  async updateGroupOnly({ dispatch }, input) {
    try {
      const groupData = await dispatch(
        'api/mutate',
        { mutation: 'updateGroup', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      return groupData;
    } catch (error) {
      console.error('fail to update Group', error);
      return false;
    }
  },

  async publicGroup({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'createPublicGroup', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to public Group', error);
      return Promise.reject();
    }
  },

  async privateGroup({ dispatch }, id) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'deletePublicGroup', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to private Group', error);
      return Promise.reject();
    }
  },

  async updateGroup({ commit, dispatch }, input) {
    try {
      const groupData = await dispatch(
        'api/mutate',
        { mutation: 'updateGroup', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_GROUP', groupData);
      return groupData;
    } catch (error) {
      console.error('fail to update Group', error);
      return false;
    }
  },

  async joinGroup({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'createJoinGroup', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to join Group', error);
      return false;
    }
  },

  async removeFromGroup({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'deleteJoinGroup', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to remove from Group', error);
      return false;
    }
  },
};

export const getters = {
  currentGroup: (state) => state.currentGroup,
  currentGroups: (state) => state.currentGroups,
};
