import { Storage } from 'aws-amplify';
import awsconfig from '../aws-exports';

function sortByCreatedAt(a, b) {
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  return 0;
}

export const state = () => ({
  adminSpace: {},
  currentSpace: {},
  publicSpaces: [],
  spaceMembers: [],
  listSpaces: [],
});

export const mutations = {
  SET_SPACE(state, space) {
    if (space && space.status !== 'inactive') {
      state.currentSpace = space;
    }
    state.adminSpace = space;
  },
  SET_SPACES(state, payload) {
    state.listSpaces = payload;
  },
  SET_PUBLIC_SPACES(state, payload) {
    state.publicSpaces = payload;
  },
  SET_SPACE_MEMBERS(state, payload) {
    state.spaceMembers = payload;
  },
  REMOVE_PROFILE_REQUEST(state, id) {
    const profiles =
      state.currentSpace?.profiles.items.filter(
        (obj) => obj.status === 'waiting' && obj.id !== id // eslint-disable-line
      ) || [];
    state.currentSpace.profiles.items = [...profiles];
  },
  REMOVE_JOIN_KEY(state, id) {
    const { joinSpaceKey } = state.currentSpace;
    if (!joinSpaceKey || joinSpaceKey.items.length === 0) return;
    const newListJoinSpaceKey = joinSpaceKey.items.filter((key) => key.id !== id);
    state.currentSpace.joinSpaceKey.items = [...newListJoinSpaceKey];
  },
  CHANGE_SPACE_INFO(state, updateInfo) {
    state.currentSpace = {
      ...state.currentSpace,
      planID: updateInfo.planID,
      planName: updateInfo.planID,
      maxStorage: updateInfo.maxStorage,
      maxMember: updateInfo.maxMember,
      monthlyUpload: updateInfo.monthlyUpload,
    };
  },
};

export const actions = {
  async getSpace({ commit, dispatch }, id) {
    try {
      const spaceData = await dispatch('api/get', { query: 'getSpace', id }, { root: true });
      commit('SET_SPACE', spaceData);
      return spaceData;
    } catch (error) {
      console.error('fail to get Space', error);
      return false;
    }
  },

  async getSpacePublic({ dispatch }, id) {
    try {
      const spaceData = await dispatch(
        'api/getPublic',
        { query: 'getSpacePublic', id },
        { root: true } // eslint-disable-line comma-dangle
      );
      return spaceData;
    } catch (error) {
      console.error('fail to get Space', error);
      return false;
    }
  },

  async listSpace({ commit, dispatch }, { filter, limit, nextToken, query = 'listSpaces' }) {
    try {
      const spaces = await dispatch(
        'api/query',
        { query, filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACES', spaces);
      return spaces;
    } catch (e) {
      console.error('fail to list Space', e);
      return false;
    }
  },

  async listPublicSpace({ commit, dispatch }, { filter, limit, nextToken }) {
    const publicSpaces = await dispatch(
      'api/query',
      { query: 'listPublicSpaces', filter, limit, nextToken },
      { root: true } // eslint-disable-line comma-dangle
    );
    commit('SET_PUBLIC_SPACES', publicSpaces);
    return publicSpaces;
  },

  async publicSpace({ dispatch }, publicInput) {
    try {
      const publicSpaceData = await dispatch(
        'api/mutate',
        { mutation: 'createPublicSpace', input: publicInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      dispatch('listPublicSpace', {});
      return publicSpaceData;
    } catch (error) {
      console.error('fail to public Space', error);
      return Promise.reject();
    }
  },

  async updatePublicSpace({ dispatch }, publicInput) {
    try {
      const publicSpaceData = await dispatch(
        'api/mutate',
        { mutation: 'updatePublicSpace', input: publicInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      dispatch('listPublicSpace', {});
      return publicSpaceData;
    } catch (error) {
      console.error('fail to update public Space', error);
      return Promise.reject();
    }
  },

  async privateSpace({ dispatch }, id) {
    try {
      const publicSpaceData = await dispatch(
        'api/mutate',
        { mutation: 'deletePublicSpace', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
      dispatch('listPublicSpace', {});
      return publicSpaceData;
    } catch (error) {
      console.error('fail to private Space', error);
      return Promise.reject();
    }
  },

  async createSpace({ commit, dispatch }, { input, avatarData }) {
    let avatar = null;
    if (avatarData) {
      const {
        aws_user_files_s3_bucket: bucket,
        aws_user_files_s3_bucket_region: region,
      } = awsconfig;
      const { size, type: mimeType } = avatarData;
      const timeStamp = new Date().getTime();
      const regex = /(?:\.([^.]+))?$/;
      const type = regex.exec(avatarData.name)[1];
      const key = `space/avatar/${timeStamp}.${type}`;
      avatar = {
        region,
        bucket,
        key,
      };
      if (size <= 2 * 1024 * 1024) {
        try {
          await Storage.put(key, avatarData, {
            contentType: mimeType,
          });
        } catch (error) {
          console.error('fail to upload Avatar', error);
          return Promise.reject();
        }
      } else {
        console.error('image size need to be smaller than 10MB !');
        return Promise.reject();
      }
    }
    const spaceInput = {
      ...input,
      avatar,
      isActiveCoachRoom: false,
    };
    try {
      const spaceData = await dispatch(
        'api/mutate',
        { mutation: 'createSpace', input: spaceInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACE', spaceData);
      return spaceData;
    } catch (error) {
      console.error('fail to create Space', error);
      return Promise.reject();
    }
  },
  async updateSpace({ dispatch }, { input, avatarData }) {
    let { avatar } = input;
    if (avatarData) {
      const {
        aws_user_files_s3_bucket: bucket,
        aws_user_files_s3_bucket_region: region,
      } = awsconfig;
      const { size, type: mimeType } = avatarData;
      const timeStamp = new Date().getTime();
      const regex = /(?:\.([^.]+))?$/;
      const type = regex.exec(avatarData.name)[1];
      const key = `space-avatar-${timeStamp}.${type}`;
      avatar = {
        region,
        bucket,
        key,
      };
      if (size <= 2 * 1024 * 1024) {
        try {
          await Storage.remove(avatar.key, {
            contentType: mimeType,
          });
          await Storage.put(key, avatarData, {
            contentType: mimeType,
          });
        } catch (error) {
          console.error('fail to upload Avatar', error);
          return Promise.reject();
        }
      } else {
        console.error('image size need to be smaller than 10MB !');
        return Promise.reject();
      }
    }
    let spaceInput = { ...input };
    if (avatar) {
      spaceInput = {
        ...input,
        avatar,
      };
    }
    try {
      const spaceData = await dispatch(
        'api/mutate',
        { mutation: 'updateSpace', input: spaceInput },
        { root: true } // eslint-disable-line comma-dangle
      );
      // const { joinSpaceKey } = spaceData;
      // if (joinSpaceKey.items.length > 0) {
      //   const inputJoinSpace = {
      //     id: joinSpaceKey.items[0].id,
      //     avatarKey: avatar?.key,
      //     spaceName: spaceInput.name,
      //   };
      //   await dispatch('updateJoinSpaceKey', inputJoinSpace);
      // }
      dispatch('getSpace', spaceData.id);
      return spaceData;
    } catch (error) {
      console.error('fail to update Space', error);
      return Promise.reject();
    }
  },

  async updateSpaceAddMember({ dispatch, commit }, input) {
    try {
      const spaceData = await dispatch(
        'api/mutate',
        { mutation: 'updateSpace', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACE', spaceData);
      return spaceData;
    } catch (error) {
      console.error('fail to update Space', error);
      return Promise.reject();
    }
  },

  async updateSpaceVideoCapacity({ commit, dispatch }, { spaceID, capacity }) {
    try {
      const { currentStorage } = await dispatch(
        'api/get',
        { query: 'getSpace', id: spaceID },
        { root: true } // eslint-disable-line comma-dangle
      );
      const spaceData = await dispatch(
        'api/mutate',
        {
          mutation: 'updateSpace',
          input: {
            id: spaceID,
            // currentUpload: currentUpload - capacity,
            currentStorage: currentStorage - capacity < 0 ? 0 : currentStorage - capacity,
          }, // eslint-disable-line comma-dangle
        },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACE', spaceData);
      return spaceData;
    } catch (error) {
      console.error('fail to update Space', error);
      return Promise.reject();
    }
  },

  async listJoinSpaceKeys({ dispatch }, { filter, limit, nextToken }) {
    try {
      return await dispatch(
        'api/query',
        { query: 'listJoinSpaceKeys', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to list join space key', error);
      return Promise.reject();
    }
  },

  async getJoinSpaceKey({ dispatch }, id) {
    try {
      return await dispatch(
        'api/get',
        { query: 'getJoinSpaceKey', id },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail get join space key', error);
      return Promise.reject();
    }
  },

  async getJoinSpaceKeyPublicAccess({ dispatch }, id) {
    try {
      return await dispatch(
        'api/getPublic',
        { query: 'getJoinSpaceKey', id },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail get join space key', error);
      return Promise.reject();
    }
  },

  async createJoinSpaceKey({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'createJoinSpaceKey', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail create join space key', error);
      return Promise.reject();
    }
  },

  async updateJoinSpaceKey({ dispatch }, input) {
    try {
      return await dispatch(
        'api/mutate',
        { mutation: 'updateJoinSpaceKey', input },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail update join space key', error);
      return Promise.reject();
    }
  },

  async deleteJoinSpaceKey({ commit, dispatch }, id) {
    try {
      commit('REMOVE_JOIN_KEY', id);
      return await dispatch(
        'api/mutate',
        { mutation: 'deleteJoinSpaceKey', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail delete join space key', error);
      return Promise.reject();
    }
  },

  async updateSpaceOwner({ dispatch, commit }, input) {
    try {
      const space = await dispatch(
        'api/mutate',
        { mutation: 'updateSpace', input },
        { root: true } // eslint-disable-line comma-dangle
      );
      commit('SET_SPACE', space);
    } catch (error) {
      console.error('fail update space', error);
      return Promise.reject();
    }
  },

  setListSpace({ commit }, { spaces }) {
    commit('SET_SPACES', spaces);
    return spaces;
  },

  // async createSpaceInvitation({ dispatch }, input) {
  //   try {
  //     await dispatch(
  //       'api/mutate',
  //       { mutation: 'createSpaceInvitation', input },
  //       { root: true } // eslint-disable-line comma-dangle
  //     );
  //   } catch (error) {
  //     console.error('fail to create space invitation', error);
  //     return Promise.reject();
  //   }
  // },

  async deleteSpaceInvitation({ dispatch }, id) {
    try {
      await dispatch(
        'api/mutate',
        { mutation: 'deleteSpaceInvitation', input: { id } },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('fail to delete space invitation', error);
      return Promise.reject();
    }
  },

  async listSpaceInvitations({ dispatch }, { filter, limit, nextToken }) {
    try {
      return await dispatch(
        'api/query',
        { query: 'listSpaceInvitations', filter, limit, nextToken },
        { root: true } // eslint-disable-line comma-dangle
      );
    } catch (error) {
      console.error('list space invitations fail: ', error);
      return Promise.reject();
    }
  },

  async removeProfileRequest({ commit }, id) {
    commit('REMOVE_PROFILE_REQUEST', id);
  },

  async settingSpaceInfo({ commit }, input) {
    commit('CHANGE_SPACE_INFO', input);
  },
};

export const getters = {
  adminSpace: (state) => state.adminSpace,
  currentSpace: (state) => state.currentSpace,
  publicSpaces: (state) => state.publicSpaces,
  spaceMembers: (state) => state.spaceMembers,
  listSpaces: (state) => state.listSpaces.sort(sortByCreatedAt),
};
